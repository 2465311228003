import { Box, Button, Loader, OptionsPanel, Tag, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MainContainer } from "../common/MainContainer";
import ManageCommunityCard from "./ManageCommunityCard";
import { StatusFilter } from "./ManageCommunityCard/common";
import useManagePage from "./useManagePage";

const BUTTON_PROPS = {
  color: "02Primary500",
  borderRadius: "8px",
  bgColor: "03Secondary300",
  rightIcon: "pen-to-square",
  height: "72px",
  spacing: "mt-0",
  borderColor: "03Secondary300",
  hoverBorderColor: "03Secondary300",
  hoverBackground: "03Secondary300",
  hoverColor: "02Primary500",
};

const OverviewTab = () => {
  const navigate = useNavigate();
  const { managedCommunities, loading, refresh, onStatusChange, status } = useManagePage();
  const [expanded, setExpanded] = useState(null);
  let content;

  const onStart = (event) => {
    const { id } = event.currentTarget.dataset;
    navigate(`${id}`);
  };

  const toggleCommunity = (e) => {
    const commId = e.target.dataset.id;
    if (expanded == commId) {
      setExpanded(null);
    } else {
      setExpanded(commId);
    }
  };

  const onStatusClick = (e) => {
    const value = e.target.dataset.value;
    onStatusChange({ value });
  };

  const onContributionsClick = (e) => {
    debugger;
  };

  if (loading) {
    content = (
      <Box width="100%" spacing="m-a p-9+6" flex alignItems="center" justifyContent="center">
        <Loader $size="50px" />
      </Box>
    );
  } else {
    content = (
      <StyledFlex gap="1.5rem" spacing="mb-7">
        {managedCommunities.map((data) => (
          <ManageCommunityCard
            key={data.id}
            expanded={expanded == data.id}
            toggle={toggleCommunity}
            {...data}
            hasFooter
            onStart={onStart}
            buttonLabel="Manage Contributions"
            numWaysToContribute={data.active_listings_count > 0 && data.active_listings_count}
            numActiveSeasons={data.active_seasons_count > 0 && data.active_seasons_count}
            subheading={data.subheadline}
            buttonProps={BUTTON_PROPS}
            ButtonComponent={data.status === "draft" ? DraftButtons : undefined}
            ActionsPanel={data.status === "draft" ? ActionsPanel : undefined}
            refresh={refresh}
          />
        ))}
      </StyledFlex>
    );
  }

  return (
    <MainContainer>
      <Box mspacing="mx-a">
        <StatusFilter status={status} onStatusClick={onStatusClick}>
        </StatusFilter>
        {content}
      </Box>
    </MainContainer>
  );
};

const ActionsPanel = ({ id, refresh }) => {
  const { deleteDraft } = useStoreActions((actions) => actions.community);
  const onClick = () => deleteDraft(id).then(refresh);

  return (
    <Box flex alignItems="center" color="02Primary500">
      <Tag bgColor="error" color="01Primary0">
        Draft
      </Tag>
      <OptionsPanel right="0" hasBackground>
        <Text onClick={onClick} pointer typography="body/medium" color="error2">
          Delete Draft
        </Text>
      </OptionsPanel>
    </Box>
  );
};

const DraftButtons = ({ id, refresh }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const edit = () => showModal({ modalName: "CommunityEditorModal", communityID: id });
  const doPublish = () => showModal({ modalName: "PublishCommunityModal", id, editPath, callBack: refresh });
  const direction = isMobile ? "column" : "row";

  return (
    <DraftButtonsContainer flex spacing="mt-5" gap="1.5rem" direction={direction}>
      <Button data-id={id} onClick={doPublish}>
        Publish Community
      </Button>

      <Button onClick={edit} data-id={id} kind="outline" borderColor="02Secondary500">
        Keep Editing
      </Button>
    </DraftButtonsContainer>
  );
};

const DraftButtonsContainer = styled(Box)`
  && {
    > * {
      flex-grow: 1;
      width: unset;
    }

    a {
      display: grid;
    }
  }
`;

const StyledFlex = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default OverviewTab;
