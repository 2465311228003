import { Box, Button, IconSvg, Input, Modal, MultiSelect, Text } from "@thrivecoin/ui-components";
import { debouncePromise } from "@utils/debounce";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import Separator from "../common/Separator";
import useOnChange from "../hooks/useOnChange";

const DEFAULT_STATE = {
  id: undefined,
  listing_id: undefined,
  reward_amount: undefined,
  user_id: undefined,
};

const DEBOUNCE_TIME = 1 * 1000;

const CreateContributionModal = ({ hideModal, id, callBack }) => {
  const { createContributionRequest } = useStoreActions((actions) => actions.season);
  const { getCommunityListingsRequest } = useStoreActions((actions) => actions.listings);
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const { byID } = useStoreState((state) => state.season);
  const { searchRequest } = useStoreActions((actions) => actions.user);
  const [state, setState] = useState({ ...DEFAULT_STATE });
  const { reward_amount } = state;
  const onChange = useOnChange(setState);
  const season = byID(id);
  const [users, setUsers] = useState([]);
  const [listings, setListings] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const invalid = [selectedUsers.length, selectedListings.length, reward_amount].some((val) => !val);

  const onClick = (event) => {
    const params = {
      id,
      reward_amount,
      listing_id: selectedListings[0].id,
      user_id: selectedUsers[0].user_id,
    };
    setLoading(true);
    createContributionRequest(params)
      .then(callBack)
      .then(hideModal)
      .catch((error)=>{
        dangerToast({content: error.response.data.message })
      })
      .finally(() => setLoading(false));
  };

  const _contributorFilterFN = (keyword) =>
    searchRequest({ keyword }).then(({ profiles }) => {
      setUsers(profiles);
      return profiles;
    });

  const _listingFilterFN = (keyword) =>
    getCommunityListingsRequest({ id: season.community_id, per_page: 100, keyword }).then((listings) => {
      setListings(listings);
      return listings;
    });

  const contributorFilterFN = debouncePromise(_contributorFilterFN, DEBOUNCE_TIME);
  const listingFilterFN = debouncePromise(_listingFilterFN, DEBOUNCE_TIME);

  const onItemClicked = (setter) => (clickedObject) => {
    setter((prev) => {
      let newState = [...prev];
      //Forcing a single item
      newState = newState.includes(clickedObject) ? [] : [clickedObject];
      return newState;
    });
  };

  const onUserClick = onItemClicked(setSelectedUsers);
  const onListingClick = onItemClicked(setSelectedListings);

  return (
    <Modal open onClose={hideModal} maxWidth="620px" paddingBody="0 24px">
      <Box flex direction="column" gap="24px" onChange={onChange}>
        <Box flex direction="column" gap="16px">
          <Text typography="heading/medium" color="02Primary500" bold>
            Create Contribution
          </Text>
          <Text typography="body/medium" bold>
            Create a contribution made during {season.title}.
          </Text>
        </Box>
        <Separator />
        <TitledBox title="Contributor (Required)">
          <MultiSelect
            hideX
            name="user_id"
            valueProp="username"
            labelProp="username"
            options={users}
            filterFN={contributorFilterFN}
            onItemClick={onUserClick}
          />
        </TitledBox>
        <TitledBox title="Way to Contribute (Required)">
          <MultiSelect
            hideX
            valueProp="id"
            labelProp="name"
            name="listing_id"
            options={listings}
            filterFN={listingFilterFN}
            onItemClick={onListingClick}
          />
        </TitledBox>
        <Separator />
        <TitledBox title="Contribution Value (Required)" maxWidth="320px">
          <Box flex gap="16px">
            <Input name="reward_amount" value={reward_amount} placeholder="Eg. 50" />
            <RewardIcon icon="logo-gold-circle" />
          </Box>
        </TitledBox>
        <Separator />
        <Box flex gap="16px" width="100%" spacing="mt-5">
          <Button
            onClick={hideModal}
            kind="outline"
            variant="white"
            borderRadius="50px"
            width="100%"
            modal
            loading={loading}
          >
            Cancel
          </Button>
          <Button borderRadius="50px" width="100%" modal disabled={invalid} onClick={onClick} loading={loading}>
            Create Contribution
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const TitledBox = ({ title, children, ...props }) => (
  <Box flex gap="12px" direction="column" {...props}>
    <Text typography="Body/Medium" bold>
      {title}
    </Text>
    {children}
  </Box>
);

const RewardIcon = styled(IconSvg)`
  fill: ${({ iconColor }) => iconColor};
  width: 41px;
`;

export default CreateContributionModal;
