import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { THRIVE_DISCORD_LINK, THRIVE_TWITTER_LINK } from "../constants";
import { CommunityContext } from "./CommunityContext";

const Footer = () => {
  const { isCustomDomain } = useContext(CommunityContext);
  const { pathname } = useLocation();
  const isOnManage = pathname.includes("/manage");

  if (isCustomDomain && isOnManage) {
    return <></>;
  }

  if (isCustomDomain) {
    return (
      <FooterStyled flex justifyContent="center" bgColor="navigation" spacing="pt-3 pb-5">
        <a href="https://app.thrivecoin.com" target="_blank" rel="noreferrer">
          <Box flex alignItems="flex-end" gap="14px" color="01Primary700">
            <Text size="15px" lHeight="15px">
              Powered by ThriveCoin
            </Text>
            <IconSvg icon="leaf" color="01Primary700" width="25px" />
          </Box>
        </a>
      </FooterStyled>
    );
  }

  return (
    <FooterStyled flex justifyContent="center" alignItems="center" bgColor="navigation" height="63px" gap="12px">
      <Box flex gap="12px">
        <a aria-label="Twitter" target="_blank" href={THRIVE_TWITTER_LINK} rel="noreferrer">
          <IconWrapper borderColor="#00acee" backgroundColor="#00acee">
            <IconSvg icon="twitter" width="20px" />
          </IconWrapper>
        </a>
        <a aria-label="Discord" target="_blank" href={THRIVE_DISCORD_LINK} rel="noreferrer">
          <IconWrapper borderColor="#" backgroundColor="#4253E4">
            <IconSvg icon="discord" width="20px" />
          </IconWrapper>
        </a>
        <a aria-label="Mail" href="mailto:support@thrivecoin.com">
          <IconWrapper borderColor="#c71610" backgroundColor="#c71610">
            <IconSvg icon="envelope" width="15px" />
          </IconWrapper>
        </a>
      </Box>
      <Terms flex gap="12px">
        <StyledLink href="/terms_services" target="_blank" rel="noreferrer">
          Terms of Service
        </StyledLink>
        <StyledLink href="/terms_privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </StyledLink>
      </Terms>
    </FooterStyled>
  );
};

const FooterStyled = styled(Box)`
  position: sticky;
  bottom: 0;
  z-index: 99;
  left: 0;
  width: 100%;

  & > a {
    text-decoration: none;
  }
`;

const IconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.colors["02Primary400"]};
  svg {
    overflow: overlay;
  }
  &:hover {
    color: white;
    border-color: ${({ borderColor }) => borderColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

const StyledLink = styled.a`
  font-size: 12px;
  color: ${({ theme }) => theme.colors["01Primary700"]};
  @media ${breakpoints.mobile} {
    font-size: 10px;
    line-height: 1;
  }
`;

const Terms = styled(Box)`
  position: absolute;
  right: 24px;
  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;

export default Footer;
