import { Box, Loader, TabsContainer, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { HeaderBox } from "../ManageCommunities/ManageTabsContainer";
import ContributionsTab from "./ContributionsTab";
import EarningsTab from "./EarningsTab";
import WaysToContributeTab from "./WaysToContributeTab";
import BreadCrumb from "./WaysToContributeTab/BreadCrumb";
import {
  ManageSeasonProvider,
  SeasonWaysToContributeContext,
} from "./WaysToContributeTab/SeasonWaysToContributeContext";

const ManageSeason = () => {
  const { id } = useParams();
  return (
    <ManageSeasonProvider id={id}>
      <_ManageSeason />
    </ManageSeasonProvider>
  );
};

const _ManageSeason = () => {
  const { season, loading } = useContext(SeasonWaysToContributeContext);
  const { title } = season;

  if (loading) {
    return (
      <Box flex justifyContent="center" spacing="my-a">
        <Loader $size="64px" />
      </Box>
    );
  }

  return (
    <Box spacing="pb-8">
      <HeaderBox spacing="pb-8 pt-4">
        <Container flex direction="column" gap="25px" maxWidth="1320px" spacing="mx-a px-6">
          <BreadCrumb {...season} />
          <Text align="center" typography="Heading/Large" color="01Primary0">
            Manage {title}
          </Text>
        </Container>
      </HeaderBox>
      <TabsContainer
        tabClasses={[WaysToContributeTab, ContributionsTab, EarningsTab]}
        headers={["Ways To Contribute", "Contributions", "Earnings"]}
        className="tabsContainer"
        bgTagContainer="surfaces"
        tabProps={{ id: season.id, title }}
      />
    </Box>
  );
};

const Container = styled(Box)`
  &&& {
    box-sizing: content-box;
  }
`;

export default ManageSeason;
