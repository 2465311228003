import { Text, UserStatusBadge } from "@thrivecoin/ui-components";

const STATUSES = ["banned", "flagged", "restricted"];

const ProfileStatusBadge = ({ profileData, isThriveAdmin, className }) => {
  const status = STATUSES.find((status) => profileData[status]);
  let text = "";
  let message = "";
  const earlyReturn = !status || (!isThriveAdmin && status !== "banned");

  if (earlyReturn) return <></>;

  if (status == "restricted") {
    const names = profileData.restricted_communities.map(({ name }) => name).join(", ");
    text = `This user is restricted by the community:<br/><b>${names}</b>.`;
  }

  if (status == "flagged") {
    text = `This user is flagged by The Missionaries for <b>${profileData.flagged_reason}</b>.`;
  }

  if (status === "banned") {
    text = profileData.banned_reason
      ? `This user is banned for <b>${profileData.banned_reason}</b>.`
      : "This user has been banned and cannot participate in any community on ThriveCoin. Learn more about Bans.";

    message = (
      <Text typography="Body/Small">
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </Text>
    );
  }

  return (
    <div className={className}>
      <UserStatusBadge status={status} message={message} />
    </div>
  );
};

export default ProfileStatusBadge;
