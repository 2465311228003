import SocialButton from "@common/SocialButton";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import styled from "styled-components";
import useConnectSocialMedias from "../../../hooks/useConnectSocialMedias";

const ConnectionButton = ({
  social,
  connected,
  count,
  canDisconnect,
  showCount,
  displayName,
  provider,
  override,
  multipleConnection,
  ...props
}) => {
  const { showModal, hideModal } = useStoreActions((actions) => actions.modals);
  const connectionMethod = override || useConnectSocialMedias(true)[social];
  const _showCount = showCount && count > 0;
  const onClick = () => connectionMethod(displayName, provider);
  const connectText = displayName === "Email" ? "Verify Email" : `Connect ${displayName}`;

  const onDisconnect = () => {
    showModal({
      modalName: "DisconnectSocialProfileModal",
      hideModal,
      provider,
      name: displayName,
    });
  };

  if (connected) {
    return (
      <ButtonGroup>
        <SocialButton isConnected {...props} network={social} leftIcon="check-circle-filled" modal={true}>
          {displayName} {multipleConnection ? "identities" : "identity"} verified
        </SocialButton>
        {canDisconnect && (
          <LinkBtn
            bold
            heightSize="24px"
            bgColor="transparent"
            color="error"
            leftIcon="link-2"
            kind="text"
            onClick={onDisconnect}
            modal={true}
          >
            Disconnect my {displayName} identity
          </LinkBtn>
        )}
      </ButtonGroup>
    );
  }

  return (
    <Box flex direction="column" alignItems="center">
      <SocialButton network={social} onClick={onClick} leftIcon="chain" {...props} kind="socials" modal={true}>
        {connectText}
      </SocialButton>
      {_showCount && (
        <Text align="center" spacing="mt-3" bold>
          Opens {count} more ways to contribute
        </Text>
      )}
    </Box>
  );
};

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    svg:first-child {
      margin-right: 7px;
    }
    svg:last-child {
      width: 18px;
      margin-left: 5px;
    }

    svg {
      line-height: 14px;
    }
  }
`;

const LinkBtn = styled(Button)`
  font-size: 15px;
`;

ConnectionButton.defaultProps = {
  canDisconnect: true,
  showCount: true,
  onModal: false,
};

export default ConnectionButton;
