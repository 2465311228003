import { Box, Button, Loader, TagFilters, Text } from "@thrivecoin/ui-components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STATUS_FILTERS } from "../useManagePage";

const CONFIGS = {
  default: {
    sectionBg: "01Primary100",
    boxBg: "manageCard2",
    color: "01Primary700",
  },
  upcoming: {
    sectionBg: "01Primary100",
    boxBg: "02Primary500",
    color: "02Primary0",
  },
  published: {
    sectionBg: "03Primary200",
    boxBg: "03Primary500",
    color: "02Primary0",
  },
  blue: {
    sectionBg: "01Secondary300",
    boxBg: "#FFFFFF33",
    color: "02Primary0",
    titleColor: "02Primary500",
  },
};

const configuration = (status) => CONFIGS[status] || CONFIGS.default;

export const SectionBox = ({ title, children, count, status = "default", className, ...props }) => {
  const config = configuration(status);

  return (
    <SectionWrapper bgColor={config.sectionBg} className={className} justifyContent="space-between" gap="24px" {...props}>
      <Box flex alignItems="center" gap="12px">
        {!!count && (
          <Counter $color="02Primary500" typography="heading/small" extraBold>
            {count}
          </Counter>
        )}
        <Text typography="heading/small" color={config.titleColor} bold>
          {title}
        </Text>
      </Box>
      {children}
    </SectionWrapper>
  );
};

export const RectangleBox = ({ children, status = "default", lgSize, ...props }) => {
  const config = configuration(status);

  return (
    <RectangleWrapper bgColor={config.boxBg} color={config.color} lgSize={lgSize} {...props}>
      {children}
    </RectangleWrapper>
  );
};

export const CircleBox = ({ status = "default", children, bgColor, ...props }) => {
  const config = configuration(status);

  return (
    <CircleWrapper bgColor={bgColor || config.boxBg} {...props}>
      {children}
    </CircleWrapper>
  );
};

export const EditButton = ({ disabled, ...props }) => {
  const variant = disabled ? "01Primary110" : "01Primary700";

  return (
    <CustomButton
      color={variant}
      variant={variant}
      kind="outline"
      rightIcon="pen"
      height="52px"
      borderRadius="50px"
      isDisabled={disabled}
      {...props}
    />
  );
};

export const StatusFilter = ({ onStatusClick, filters = STATUS_FILTERS, ...props }) => {
  const [tagOptions, setTags] = useState([]);

  const onTagclick = (e) => {
    const { value } = e.currentTarget.dataset;
    const newFilters = structuredClone(filters);
    newFilters.forEach((filter) => (filter.selected = filter.value == value));
    setTags(newFilters);
    onStatusClick(e);
  };

  useEffect(() => {
    setTags(structuredClone(filters));
  }, [filters]);

  return <TagFilters width="100%" bgColor="seasonFilters" tagOptions={tagOptions} onTagClick={onTagclick} {...props} />;
};

StatusFilter.defaultProps = {
  spacing: "my-5 py-5 px-4+1",
};

const SectionWrapper = styled(Box)`
  border-radius: 5px;
  flex: 1;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`;

const RectangleWrapper = styled(Box)`
  height: ${({ lgSize }) => (lgSize ? lgSize : "56px")};
  border-radius: 8px;
`;

const CircleWrapper = styled(Box)`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomButton = styled(Button)`
  ${({ isDisabled }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

const Counter = styled(Text)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  ${({ $color, theme }) => $color && `color: ${theme.colors[$color] || $color};`}
`;

export const LoadingSection = ({ title }) => (
  <SectionBox title={title}>
    <Box grow={1} flex alignItems="center" justifyContent="center">
      <Loader $size="32px" />
    </Box>
  </SectionBox>
);
