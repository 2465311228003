import { Box, breakpoints, ContextMenu, IconSvg, Text } from "@thrivecoin/ui-components";
import truncateText from "@utils/truncateText";
import { referralLink } from "@utils/UrlHelpers";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE } from "../../Constants";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { StyledAvatar, TopWrapper } from "./common";
import ProfileStatusBadge from "./ProfileStatusBadge";
import usePanelOptions from "./usePanelOptions";

const RESTRICTIVE_ACTIONS = ["ban", "flag", "restrict"];
const ALL_RESTRICTIVE_ACTIONS = [...RESTRICTIVE_ACTIONS, "unban", "unflag", "unrestrict"];

const TopPart = ({ showEdit, profileData, isPublicProfile }) => {
  const { isThriveAdmin } = useStoreState((state) => state.user);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { isDesktop } = useStoreState((state) => state.media);
  const { getPublicUserRequest } = useStoreActions((actions) => actions.authentication);
  const { getManagedCommunities } = useStoreActions((actions) => actions.community);
  const { getProfileRequest } = useStoreActions((actions) => actions.authentication);
  const { managedCommunities } = useStoreState((state) => state.community);
  const { referral_code, address, image_url, username, description, verification_code, user_id, user_badges } =
    profileData;
  const link = referralLink(referral_code);
  const onCopyClipboard = useCopyToClipboard(link, "Your referral URL has been copied.");
  const onCopyAddress = useCopyToClipboard(
    address,
    isPublicProfile ? "The address has been copied." : "Your address has been copied."
  );
  const onCopyCode = useCopyToClipboard(verification_code, "Your code has been copied.");
  const onCopyProfile = useCopyToClipboard(window.location.href, "This profile link has been copied.");
  const { panelOptions } = usePanelOptions(isPublicProfile, isThriveAdmin, profileData);
  const image = image_url || DEFAULT_PROFILE_IMAGE;
  const handleEdit = () => showEdit(true);
  const unchangedUsername = address === username;
  const badgesToShow = user_badges ? user_badges.slice(0, 5) : [];
  const placeholders = [...Array(5 - badgesToShow.length)];

  const onClickAction = (e) => {
    const type = e.target.dataset.action;
    const afterClose = () => (isPublicProfile ? getPublicUserRequest(user_id) : getProfileRequest());
    if (ALL_RESTRICTIVE_ACTIONS.includes(type)) {
      const modalName = RESTRICTIVE_ACTIONS.includes(type) ? "DoActionsModal" : "UndoActionsModal";
      showModal({ modalName, type, image, username, id: user_id, profileData, afterClose });
    } else {
      if (type === "edit") {
        handleEdit();
      } else if (type === "invite") {
        onCopyClipboard();
      } else if (type === "share") {
        onCopyProfile();
      } else if (type === "manageBadges") {
        showModal({ modalName: "ManageUserBadges", profileData, afterClose, communities: managedCommunities });
      }
    }
  };

  const CopyButtons = () => (
    <CopiesBox isPublic={isPublicProfile}>
      <CopyWrapper isPublic={isPublicProfile} onClick={onCopyAddress}>
        <IconSvg width="24px" icon="wallet-filled" />
        <Text bold md-spacing="mx-a" sm-spacing="mx-a">
          {truncateText(address, 6)}
        </Text>
        <IconBox flex gap="10px" alignItems="center" spacing="ml-a" md-spacing="ml-0" sm-spacing="ml-0">
          <IconSvg width="14px" color="01Primary700" icon="copy" />
        </IconBox>
      </CopyWrapper>

      {!isPublicProfile && (
        <CopyWrapper onClick={onCopyCode}>
          <IconSvg color="white" width="24px" icon="person-circle-check" />
          <Text bold md-spacing="mx-a" sm-spacing="mx-a">
            {truncateText(verification_code, 6)}
          </Text>
          <IconBox flex gap="10px" alignItems="center" spacing="ml-a" md-spacing="ml-0" sm-spacing="ml-0">
            <IconSvg width="14px" color="01Primary700" icon="copy" />
          </IconBox>
        </CopyWrapper>
      )}
    </CopiesBox>
  );

  useEffect(() => {
    if (isThriveAdmin) {
      getManagedCommunities(["active"]);
    }
  }, [isThriveAdmin]);

  return (
    <TopWrapper header isPublic={isPublicProfile} description={description}>
      <StyledAvatar src={image} alt="user" />
      <CenterBox>
        <Box flex justifyContent="space-between" minWidth="100%">
          <Username
            typography={isDesktop ? "heading/x-large" : "heading/large"}
            color="01Primary700"
            spacing="mb-4"
            bold
          >
            {unchangedUsername ? truncateText(username, 6) : username}
          </Username>
        </Box>

        <Paragraph typography="body/medium" bold color="01Primary700">
          {description}
        </Paragraph>
        <Box spacing="mt-a" flex alignItems="center" justifyContent="space-between">
          {badgesToShow.map((item, i) => (
            <BadgeItem key={i} src={item.badge_logo_url} />
          ))}
          {placeholders.map((_, i) => (
            <BadgePlaceholder key={i} />
          ))}
        </Box>
      </CenterBox>
      <ActionsBox grow={1} flex direction="column">
        <Box flex alignItems="center" justifyContent="space-between">
          <Box>
            <ProfileStatusBadge profileData={profileData} isThriveAdmin={isThriveAdmin} />
          </Box>
          <ContextMenu
            ContextButton={ContextButton}
            bgColorContainer="01Primary110"
            leftContainer={isDesktop && "56px"}
            topContainer={isDesktop && "-8px"}
          >
            <ul>
              {panelOptions
                .filter((item) => !!item.condition)
                .map((item, index) => (
                  <li key={index} onClick={onClickAction} data-action={item.action}>
                    {item.label}
                  </li>
                ))}
            </ul>
          </ContextMenu>
        </Box>
        {isDesktop && <CopyButtons />}
      </ActionsBox>
      {!isDesktop && <CopyButtons />}
    </TopWrapper>
  );
};

const ContextButton = ({ onClick }) => {
  return (
    <StyledBox spacing="p-2" flex color="01Primary700">
      <IconSvg icon="more-vertical" onClick={onClick} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border: 2px solid ${({ theme }) => theme.colors["01Primary300"]};
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
`;

const CopyWrapper = styled(Box)`
  border-radius: 4px;
  height: 44px;
  min-height: 44px;
  ${({ isPublic }) => !isPublic && "flex: 1;"}
  cursor: pointer;
  padding: 0 16px;
  ${({ isPublic }) => isPublic && "padding-right: 32px;"}
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.colors["01Primary110"]};

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colors["inputHovers"]};
  }

  @media ${breakpoints.tablet} {
    padding: 0 16px;
    gap: 4px;
    flex: 1;
  }

  @media ${breakpoints.mobile} {
    flex: unset;
    padding: 0 16px;
  }
`;

const IconBox = styled(Box)`
  cursor: pointer;
`;

const Paragraph = styled(Text)`
  margin: 0 0 24px;
  max-width: 520px;
  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
  }
`;

const CenterBox = styled(Box)`
  width: 310px;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  grid-area: details;

  @media ${breakpoints.tablet} {
    width: unset;
  }

  @media ${breakpoints.mobile} {
    width: unset;
    min-height: unset;
    margin-top: auto;
    order: 2;
  }
`;

const Username = styled(Text)`
  word-break: break-all;
  padding-top: 14px;
  padding-right: 36px;
  @media ${breakpoints.tablet} {
    padding-top: 0;
    padding-right: 0;
  }
  @media ${breakpoints.mobile} {
    padding-right: 46px;
    padding-top: unset;
  }
`;

const CopiesBox = styled(Box)`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  grid-area: buttons;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    bottom: ${({ isPublic }) => (isPublic ? "24px" : "84px")};
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    order: 2;
  }
`;

const BadgeItem = styled.img`
  width: 47px;
  min-width: 47px;
  height: 47px;
`;

const BadgePlaceholder = styled(Box)`
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors["01Primary110"]};
`;

const ActionsBox = styled(Box)`
  grid-area: actions;

  @media ${breakpoints.mobile} {
    order: 1;
  }
`;

export default TopPart;
