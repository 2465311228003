import { Box, Button, IconSvg, Modal, Text } from "@thrivecoin/ui-components";
import { normalize } from "@utils/socialUtils";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useConnectSocialMedias from "../hooks/useConnectSocialMedias";
import ConnectionButton from "./ConnectSocialMediasModal/SocialButtons/ConnectionButton";

const STAMPS_URL = "https://passport.gitcoin.co/#/dashboard";

const GitcoinConnectModal = ({ hideModal, requiredScore }) => {
  const [loading, setLoading] = useState(false);
  const [hide, setHidden] = useState(false);
  const { user } = useStoreState((state) => state.user);
  const { dangerToast, successToast } = useStoreActions((actions) => actions.toasts);

  const { score } = user.socialProfiles.find((profile) => profile.provider == "gitcoin") || {};
  const _score = parseFloat(score) || 0;
  const success = parseFloat(score) > requiredScore;
  let config = normalize("gitcoin");
  const connectRefresh = useConnectSocialMedias(true)["gitcoin"];
  const hasAScore = score !== undefined;
  const ContentKlass = hasAScore ? ConnectedContent : NotConnectedContent;

  const override = () => {
    setLoading(true);
    connectRefresh()
      .catch((e) => {
        const message = e.response?.data.data.errors.uid[0];
        if (message) {
          dangerToast({ content: message });
        }
      })
      .finally(() => setLoading(false));
  };

  const refresh = () => {
    setLoading(true);
    connectRefresh()
      .then(() => {
        successToast({ content: "Your Gitcoin Passport score has been updated." });
        setHidden(true);
      })
      .finally(() => setLoading(false));
  };

  const params = {
    override,
    refresh,
    config,
    _score,
    loading,
    success,
    hide,
  };

  return (
    <Modal open onClose={hideModal} maxWidth="764px" spacing="px-9+4" md-spacing="px-4" sm-spacing="px-4">
      <Box flex direction="column">
        <StyledIcon icon="gitcoin" width="55px" />
        <Text typography="heading/large" spacing="mx-a my-5" align="center">
          This Community Requires Gitcoin Passport
        </Text>
        <Text spacing="mb-6" align="center">
          For your contributions to the ShapeShift community to be recognized, you must have a{" "}
          <StyledLink to="https://passport.gitcoin.co/#/dashboard" target="_blank">
            Gitcoin Passport
          </StyledLink>{" "}
          <b>score of {requiredScore} or higher.</b>
        </Text>
        <Box flex direction="column" gap="24px">
          <ContentKlass {...params} />

          <Button
            borderRadius="50px"
            kind="outline"
            color="02Primary500"
            variant="02Primary500"
            hoverColor="02Primary500"
            hoverBorderColor="02Primary500"
            onClick={hideModal}
            modal={true}
          >
            View Ways to Contribute
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const NotConnectedContent = ({ hasAScore, loading, override, config }) => {
  return (
    <ConnectionButton
      color="02Primary0"
      rightIcon="gitcoin2"
      canDisconnect={false}
      {...config}
      connected={hasAScore}
      loading={loading}
      override={override}
      onClick={override}
      width="100%"
      modal={true}
    />
  );
};

const ConnectedContent = ({ _score, refresh, loading, success, hide }) => {
  return (
    <>
      <StyledText typography="body/medium" $bgColor="02PrimaryGrey100" spacing="mb-3" bold $success={success}>
        <IconSvg icon="check-circle-filled" />
        {`Your Gitcoin Passport score is ${_score}`}
      </StyledText>

      <StyledLink2 to={STAMPS_URL} target="_blank">
        Add Stamps to Increase Your Score
      </StyledLink2>

      {!hide && (
        <Button
          loading={loading}
          onClick={refresh}
          leftIcon="sync"
          borderRadius="50px"
          kind="outline"
          color="02Primary500"
          variant="02Primary500"
          borderColor="01Secondary500"
        >
          Refresh Gitcoin Passport Score
        </Button>
      )}
    </>
  );
};

const StyledIcon = styled(IconSvg)`
  align-self: center;
`;

const StyledLink = styled(Link)`
  color: #4f99b9;
  text-decoration: none;
  font-weight: bold;
`;

const StyledLink2 = styled(Link)`
  text-decoration: none;
  text-align: center;
  padding: 16px 32px;
  border-radius: 40px;
  ${({ theme }) => `
  background: ${theme.colors["03Primary500"]};
  color: ${theme.colors["02Primary0"]}
   `}
`;

const StyledText = styled(Text)`
  background: ${({ $bgColor, theme }) => theme.colors[$bgColor]};
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 10px;

  svg {
    display: none;
    ${({ $success }) => $success && `display: block;`}
  }

  ${({ $success, theme }) =>
    $success &&
    `
      background:  ${theme.colors["03Primary150"]};
      color: ${theme.colors["03Primary500"]};

      ${"" /* color: ${theme.colors["success500"]}; */}
  `}
`;

export default GitcoinConnectModal;
