import { Box, SearchInput, Text } from "@thrivecoin/ui-components";
import debounce from "@utils/debounce";
import { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { IconContextMenu, SeasonListingOptions } from "../common";
import ListingsTable from "./ListingsTable";
import SeasonListingRow from "./SeasonListingRow";
import { SeasonWaysToContributeContext } from "./SeasonWaysToContributeContext";
import useSelectionColumn from "./useSelectionColumn";
const DEBOUNCE_TIME = 3000;

const cancelEvent = (event) => event.preventDefault();

const ListingDropBox = ({ onDrop, seasonListingsTotal, ...props }) => {
  useEffect(() => {
    const element = document.getElementById("listings-dropzone");
    element.ondragenter = cancelEvent;
    element.ondragover = cancelEvent;
    element.ondrop = onDrop;
  }, []);

  const Component = seasonListingsTotal ? WithListings : NoListings;

  return (
    <Box flex direction="column" gap="32px" id="listings-dropzone">
      <Component {...props} />
    </Box>
  );
};

const NoListings = ({ title }) => (
  <BorderedBox flex gap="16px" direction="column" align="center">
    <Text typography="heading/medium">No Ways to contribute yet on {title}</Text>
    <Text typography="body/medium" bold>
      Add your initial Way to Contribute by either clicking the button or selecting and dragging contributions from
      below to the designated placeholder here.
    </Text>
  </BorderedBox>
);

const WithListings = ({ listings, title, searchListings, previewListing, updateReward }) => {
  const { removeListingFromSeason } = useContext(SeasonWaysToContributeContext);
  const { selectedIDs, onSelectChange, selectAll, clearSelection, selectByStatus } =
    useSelectionColumn(".season-listings");

  const selectedCount = selectedIDs.length;
  const text =
    selectedCount > 0
      ? `${selectedCount} Selected Ways to Contribute`
      : `There are ${listings.length} Ways to Contribute in ${title}`;

  const removeFromSeason = (e) => {
    const id = e.target.dataset.id;
    removeListingFromSeason(id);
  };

  const doSearch = useMemo(() => {
    return debounce(searchListings, DEBOUNCE_TIME);
  }, []);

  const onClear = () => searchListings("");

  const removeSelected = (e) => removeListingFromSeason(selectedIDs).then(clearSelection);

  const listingProps = {
    OptionsComponent: SeasonListingOptions,
    seasonName: title,
    removeFromSeason,
    updateReward,
    previewListing,
  };

  return (
    <Container onChange={onSelectChange}>
      <Box flex gap="16px" alignItems="center">
        <Box flex gap="12px" direction="column">
          <Text typography="Heading/Small">{text}</Text>
          <Text typography="body/medium">
            You can change the default order of the ways to contribute by dragging and dropping the items bellow
          </Text>
        </Box>
        {selectedIDs.length > 0 && (
          <IconContextMenu>
            <ul>
              <li onClick={clearSelection}>Clear Selection</li>
              <li onClick={removeSelected}>Remove from {title}</li>
            </ul>
          </IconContextMenu>
        )}
        <SearchInput spacing="ml-a" onChange={doSearch} onClear={onClear} />
      </Box>

      <ListingsTable
        showStatus
        showRewards
        RowClass={SeasonListingRow}
        selectAll={selectAll}
        listings={listings}
        listingProps={listingProps}
        selectedIDs={selectedIDs}
        className="season-listings"
      >
        <ul>
          <li onClick={selectAll}>All</li>
          <li onClick={clearSelection}>None</li>
          <li onClick={selectByStatus} data-status="pending">
            Pending
          </li>
          <li onClick={selectByStatus} data-status="active">
            Active
          </li>
        </ul>
      </ListingsTable>
    </Container>
  );
};

const Container = styled(Box)`
  tr td:last-child, tr td:first-child {
    width: 1%;
    white-space: nowrap;
  }
`;

const BorderedBox = styled(Box)`
  border: 1px dashed ${({ theme }) => theme.colors["01Primary400"]};
  border-radius: 24px;
  padding: 70px 114px;
`;

ListingDropBox.defaultProps = {
  listings: [],
  onDrop: () => {},
};

export default ListingDropBox;
