import LoadMore from "@common/LoadMore";
import { Box, Button, ListingCompactModular, Loader, SearchInput } from "@thrivecoin/ui-components";
import debounce from "@utils/debounce";
import { textLinkify } from "@utils/text";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { MainContainer } from "../common/MainContainer";
import Orderable, { dragClass } from "../ListingEditor/CommunityListings/Orderable";
import Table from "../ListingEditor/CommunityListings/Table";
import useWaysToContribute from "../Season/BodySeason/WaysToContribute/useWaysToContribute";
import { StatusFilter } from "./ManageCommunityCard/common";

const { ContributionTD, RewardTD, CanBeCompletedTD, CompletionCheckTD, ConnectOrDetailsTD, EditTD } =
  ListingCompactModular;

const CELLS = [ContributionTD, RewardTD, CanBeCompletedTD, CompletionCheckTD, ConnectOrDetailsTD, EditTD];

const useListingTab = () => {
  const { id } = useParams();
  const [status, setStatus] = useState("Active");
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const { managableListings, managablePagination } = useStoreState((state) => state.listings);
  const { getCommunityListingsRequest } = useStoreActions((actions) => actions.listings);
  const { updateOrders, setProperty } = useStoreActions((actions) => actions.listings);
  const onLoadMore = (page) =>
    getCommunityListingsRequest({ id, page, isPaginating: true, status: status.toLowerCase(), keyword: text });

  const onTextChange = useMemo(() => {
    const onchange = (text) => {
      setText(text);
    };

    return debounce(onchange, 3000);
  }, []);

  const _updateOrders = ({ ids, orders }) => {
    updateOrders({ ids, orders }).then(() => {
      setProperty({ field: "managableIDs", data: ids });
    });
  };

  const onStatusChange = ({ value }) => {
    setLoading(true);
    setStatus(value);
  };

  useEffect(() => {
    id &&
      getCommunityListingsRequest({ id, status: status.toLowerCase(), keyword: text }).finally(() => setLoading(false));
  }, [id, status, text]);

  return {
    listings: managableListings,
    getListings: getCommunityListingsRequest,
    pagination: managablePagination,
    onStatusChange,
    status,
    loading,
    onTextChange,
    onLoadMore,
    updateOrders: _updateOrders,
  };
};

const ListingsTab = ({ communityID }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const createListing = searchParams.get("createListing") == "true";
  const { isThriveAdmin } = useStoreState((state) => state.user);
  const { infoToast, successToast } = useStoreActions((actions) => actions.toasts);
  const { isMobile, isDesktop, isTablet } = useStoreState((state) => state.media);
  const { archiveRequest, activateRequest, getSnapshotDetails, getActivityDetails, deleteListing } = useStoreActions(
    (actions) => actions.listings
  );
  const { showModal } = useStoreActions((actions) => actions.modals);

  const { listings, onLoadMore, pagination, updateOrders, status, onStatusChange, loading, onTextChange } =
    useListingTab();
  const { networkConnections } = useWaysToContribute();

  const onStatusClick = (e) => {
    const value = e.target.dataset.value;
    onStatusChange({ value });
  };

  const onChange = (indexes) => {
    const ids = indexes.map((index) => listings[index].id);
    const orders = [...Array(ids.length)].map((_, index) => index);

    updateOrders({ ids, orders });
  };

  const { bindItems, removeItems } = Orderable({ itemSelector: "tr.listing-row", onChange });

  const onEdit = (event) => {
    const { id } = event.target.dataset;
    showModal({ modalName: "ListingEditorModal", listingID: id });
  };

  const doArchive = (event) => {
    const { id } = event.target.dataset;
    const { name } = listings.find((listing) => listing.id == id);
    const action = () =>
      archiveRequest(id).then(() => {
        infoToast({ content: `Your contribution “${name}” has been archived.`, timeout: 5000 });
      });

    showModal({ modalName: "ArchiveListingModal", action });
  };

  const doActivate = (event) => {
    const { id } = event.target.dataset;
    const { name } = listings.find((listing) => listing.id == id);
    const action = () =>
      activateRequest(id).then(() => {
        successToast({ content: `Your contribution “${name}” is now active.`, timeout: 5000 });
      });
    showModal({ modalName: "ActivateListingModal", action });
  };

  const sharedProps = {
    onEdit,
    status: status.toLowerCase(),
    doArchive,
    doActivate,
    deleteDraft: deleteListing,
  };

  const addListing = () => showModal({ modalName: "ListingEditorModal", communityID });

  const onShare = (listingId) => {
    navigator.clipboard.writeText(`${window.location.origin}/listings/${listingId}`);
    successToast({ content: "Link was copied!", dismissTime: 3000 });
  };

  useEffect(() => {
    bindItems();

    return removeItems;
  }, [listings]);

  useEffect(() => {
    if (createListing) {
      addListing();
      searchParams.delete("createListing");
      setSearchParams(searchParams);
    }
  }, [createListing]);

  return (
    <MainContainer>
      <Container className="listings-container">
        <Box flex alignItems="center">
          <StatusFilter status={status} onStatusClick={onStatusClick}>
            <Box spacing="ml-a mr-5" flex gap="16px">
              <SearchInput className="search-input" onChange={onTextChange} />
              {isThriveAdmin && (
                <Button borderRadius="40px" height="44px" rightIcon="arrow-up-right" onClick={addListing}>
                  Add New Way to Contribute
                </Button>
              )}
            </Box>
          </StatusFilter>
        </Box>
        {!loading ? (
          <Table
            isMobile={isMobile}
            isDesktop={isDesktop}
            // onSort={onSort}
            // sortIcon={sortIcon
            // sortType={sortType}
            sortIcon={"up-long"}
            isCommunity
            confirmed
            isTablet={isTablet}
          >
            {/* <OrderableList onChange={onChange} ContainerKlass={TBody}> */}
            {listings.map((listing) => (
              <ListingCompactModular
                sharedProps={sharedProps}
                rowClass="listing-row"
                spacing="mb-5"
                key={listing.id}
                {...listing}
                isAuthenticated={true}
                reward={parseFloat(listing.reward_amount)}
                earned={parseFloat(listing.rewards_earned)}
                completedCount={listing.contributions_count || 0}
                canCompleteMultipleTimes={listing.unlimited_contributions_per_user}
                type={listing.contribution_app}
                manuallyVerification={listing.verification_method === "manual"}
                getSnapshotDetails={getSnapshotDetails}
                getActivityDetails={getActivityDetails}
                fieldClasses={CELLS}
                description={textLinkify(listing.description)}
                onShare={onShare}
                {...networkConnections(listing.contribution_app, listing.networks)}
              />
            ))}
          </Table>
        ) : (
          <Box width="100%" spacing="m-a p-9+6" flex alignItems="center" justifyContent="center">
            <Loader $size="50px" />
          </Box>
        )}
        {!!pagination && !loading && <LoadMore pagination={pagination} loadMore={onLoadMore} />}
      </Container>
    </MainContainer>
  );
};

const Container = styled(Box)`
  .spacing-tr {
    display: none;
  }

  .listing-row {
    cursor: grab;
  }

  .${dragClass} {
    outline: 1px solid ${({ theme }) => theme.colors.hover};
    cursor: grab;

    &.active {
      cursor: grabbing;
    }
  }

  .gap-row {
    td {
      padding: 2rem;
    }

    &.active td {
      outline: solid 2px ${({ theme }) => theme.colors["01Primary300"]};
      outline-offset: -32px;
    }
  }
`;

export default ListingsTab;
