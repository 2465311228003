import styled from "styled-components";
import { Table as _Table } from "../../common/TableElements";
import Headers from "./Headers";
import Row from "./Row";

const ContributorsTable = ({ items, sortProps }) => {
  return (
    <Table width="100%">
      <Headers {...sortProps} />
      <tbody>
        {items.map((item) => (
          <Row key={item.id} {...item} />
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled(_Table)`
  tbody tr td {
    padding: 24px;
  }
`;

export default ContributorsTable;
