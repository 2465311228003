import { TabsContainer } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useMemo } from "react";
import Contributors from "../../Contributors/index";
import WaysToContribute from "../../Season/BodySeason/WaysToContribute";
import AboutCommunity from "./AboutCommunity";
import Contributions from "./Contributions";
import PendingContributionsTab from "./PendingContributionsTab";
import Seasons from "./Seasons";

const LOGGED_TABS = [Seasons, AboutCommunity, Contributors, Contributions];
const HEADERS = ["Seasons", "About This Community", "Contributors", "Contributions"];

const MY_CURRENT_CHALLENGES = "My Current Challenge";

const selectedTabIndex = (tab, headers) => {
  if (tab === "communityChallenges") {
    if (headers.includes(MY_CURRENT_CHALLENGES)) {
      return 1;
    }
    return 0;
  }
  return tab;
};

const Challenges = ({ role, onChangeTab, currentTabIndex, community }) => {
  const { confirmed } = useStoreState((state) => state.user.user);
  const { isDirectAdmin } = useStoreState((state) => state.community);
  const { getActiveListingsRequest } = useStoreActions((actions) => actions.listings);
  const { resetContributorDetails } = useStoreActions((actions) => actions.contributors);
  const { id } = community;
  const customTheme = community.theme === "custom";
  const tabBgColor = customTheme ? community.accent_color : "03Primary500";
  const tabColor = customTheme ? "02Primary0" : "01Primary0";
  const statsColor = customTheme ? community.accent_color : "hover";

  const goToCommunity = (event) => {
    event?.stopPropagation();
    event?.preventDefault();
    onChangeTab(1);
  };

  const tabProps = {
    group: "community",
    role,
    setTabIndex: onChangeTab,
    id: id,
    getListings: getActiveListingsRequest,
    goToCommunity: goToCommunity,
    statsColor,
  };

  const { tabClasses, headers } = useMemo(() => {
    let headers = [...HEADERS];
    let tabClasses = [...LOGGED_TABS];

    if (isDirectAdmin) {
      headers.push("Pending Contributions");
      tabClasses.push(PendingContributionsTab);
    }

    if (community && !community.hide_amounts) {
      tabClasses.splice(1, 0, WaysToContribute);
      headers.splice(1, 0, "Ways to Contribute");
    }

    return { tabClasses, headers };
  }, [id, role, isDirectAdmin]);

  const tabIndex = selectedTabIndex(currentTabIndex, headers);

  useEffect(() => {
    if (!confirmed) {
      resetContributorDetails();
    }

    return () => {
      resetContributorDetails();
    };
  }, [confirmed]);

  return (
    <div>
      <TabsContainer
        tabProps={tabProps}
        selectedIndex={tabIndex}
        tabClasses={tabClasses}
        headers={headers}
        onChangeIndex={onChangeTab}
        className="tabsContainer"
        selectedTabBgColor={tabBgColor}
        selectedTabColor={tabColor}
        bgTagContainer="surfaces"
      />
    </div>
  );
};

export default Challenges;
