import { Box, Button, Grid, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CircleBox, LoadingSection, RectangleBox, SectionBox } from "./common";

const BadgesSection = ({ communityId }) => {
  const { getCommunityBadges } = useStoreActions((actions) => actions.badges);
  const { communityBadges } = useStoreState((state) => state.badges);
  const [loading, setloading] = useState(true);

  const onCreate = () => {
    debugger;
  };

  const onEdit = () => {
    debugger;
  };

  useEffect(() => {
    if (communityId) {
      getCommunityBadges({ community_id: communityId }).finally(() => {
        setloading(false);
      });
    }
  }, communityId);

  if (loading) {
    return <LoadingSection title="Seasons" />;
  }

  const Component = communityBadges.length > 0 ? SomeBadgesBox : NoBadgesBox;
  return <Component badges={communityBadges} />;
};

const NoBadgesBox = ({ onCreate }) => {
  return (
    <SectionBox title="Badges" status="blue">
      <Box flex gap="16px">
        <CircleBox bgColor="01Secondary500">
          <IconSvg icon="plus" color="#fff" />
        </CircleBox>
        <CircleBox bgColor="#FFFFFF33" />
        <CircleBox bgColor="#FFFFFF33" />
        <CircleBox bgColor="#FFFFFF33" />
      </Box>
      <RectangleBox relative lgSize spacing="px-8 py-6" flex alignItems="center" justifyContent="center" status="blue">
        <Text typography="body/small" color="02Primary500" center>
          No Badges created yet.
        </Text>
      </RectangleBox>
      <Button borderRadius="40px" rightIcon="plus" bgColor="01Secondary500" onClick={onCreate}>
        Create
      </Button>
    </SectionBox>
  );
};

const MAX_CIRCLES = 16;

const SomeBadgesBox = ({ badges, onEdit }) => {
  const hasAddCircle = badges.length < MAX_CIRCLES;
  const totalCircles = hasAddCircle ? badges.length + 1 : badges.length;
  const fillercount = MAX_CIRCLES - totalCircles;
  const fillers = new Array(fillercount).fill(0);

  return (
    <SectionBox title="Badges" count="3">
      <Grid columns="repeat(4, 1fr)" gap="16px" spacing="mb-4">
        {badges.map(({ logo_url, id }) => (
          <CircleBox key={id}>
            <Badge src={logo_url} />
          </CircleBox>
        ))}
        <CircleBox bgColor="#FFFFFF33">
          <IconSvg icon="plus" color="#FFFFFF" />
        </CircleBox>
        {fillers.map((_, index) => (
          <CircleBox key={index} />
        ))}
      </Grid>
      <Button
        borderRadius="40px"
        kind="outline"
        rightIcon="pen-2"
        borderColor="01Primary700"
        color="01Primary700"
        onEdit={onEdit}
      >
        Edit Badges
      </Button>
    </SectionBox>
  );
};

const Badge = styled.img`
  width: 100%;
`;

export default BadgesSection;
