import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import humanize from "@utils/humanize";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CircleBox, EditButton, LoadingSection, RectangleBox, SectionBox } from "./common";

const status = (s) =>
  s === "active" || s === "completed" || s === "raffle_completed" || s === "open_to_claim" ? "published" : status;

const detailsToShow = (seasons) => {
  let s = seasons.find((item) => item.status === "active");
  if (s) {
    return s;
  }
  s = seasons.find((item) => status(item.status) === "published");
  if (s) {
    return s;
  }
  if (seasons.length > 0) {
    return seasons[0];
  } else {
    return null;
  }
};

const formattedDates = (obj, field) => (obj[field] ? moment(obj[field]).format("DD/MM/YYYY") : "-");

const SeasonsSection = ({ communityId }) => {
  const [loading, setLoading] = useState(true);
  const { filteredSeasons } = useStoreState((state) => state.season);
  const { getFilteredSeasons } = useStoreActions((actions) => actions.season);
  const { showModal } = useStoreActions((actions) => actions.modals);

  const seasonsToShow = 4;
  const seasons = filteredSeasons.slice(0, seasonsToShow);
  const showAdd = seasonsToShow - seasons.length > 0;
  const emptyCircles = seasonsToShow - seasons.length - 1;
  const seasonDetails = detailsToShow(seasons);

  const managePath = `/manage/communities/${communityId}`;

  const createSeason = () => showModal({ modalName: "SeasonEditorModal", communityID: communityId });

  const _renderSeasons = seasons.map((item) => (
    <CircleBox key={item.id} status={status(item.status)}>
      <Text color="#fff" typography="heading/large">
        {item.order_number}
      </Text>
    </CircleBox>
  ));

  const _renderEmptyCircles =
    showAdd &&
    Array(emptyCircles)
      .fill("")
      .map((_, index) => <CircleBox key={index} />);

  const _renderSeasonDetails = seasonDetails ? (
    <RectangleBox
      lgSize="auto"
      spacing="py-3 px-4"
      gap="21px"
      flex
      direction="column"
      status={status(seasonDetails.status)}
    >
      <Box alignItems="center" justifyContent="space-between" flex>
        <Box>
          <Text bold typography="body/small">
            Season Start
          </Text>
          <Text bold typography="body/small">
            Season End
          </Text>
          <Text bold typography="body/small">
            Season Type
          </Text>
          {seasonDetails.reward_system === "raffle" && (
            <>
              <Text bold typography="body/small">
                Raffle End
              </Text>
              <Text bold typography="body/small">
                Raffle Prizes
              </Text>
            </>
          )}
        </Box>
        <Box>
          <Text bold typography="body/small">
            {formattedDates(seasonDetails, "start_date")}
          </Text>
          <Text bold typography="body/small">
            {formattedDates(seasonDetails, "end_date")}
          </Text>
          <Text bold typography="body/small">
            {seasonDetails.reward_system ? humanize(seasonDetails.reward_system) : "-"}
          </Text>
          {seasonDetails.reward_system === "raffle" && (
            <>
              <Text bold typography="body/small">
                {formattedDates(seasonDetails, "raffle_draw_date")}
              </Text>
              <Text bold typography="body/small">
                {seasonDetails.prizes ? seasonDetails.prizes.length : "-"}
              </Text>
            </>
          )}
        </Box>
      </Box>
      {seasonDetails.status === "claim_opens_soon" && (
        <Box gap="8px" flex>
          <IconSvg icon="alert-triangle" width="17px" />
          <Text typography="body/small" bold>
            Smart Contract is missing.
          </Text>
        </Box>
      )}
    </RectangleBox>
  ) : (
    <RectangleBox lgSize="130px" spacing="mb-5" flex alignItems="center" justifyContent="center">
      <Text typography="body/small">No season created yet,</Text>
    </RectangleBox>
  );

  useEffect(() => {
    getFilteredSeasons({ id: communityId }).finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <LoadingSection title="Seasons" />;
  }

  return (
    <SectionBox title="Seasons">
      <Box flex gap="16px">
        {_renderSeasons}
        {showAdd && (
          <CircleBox pointer bgColor="manageCard1" onClick={createSeason}>
            <IconSvg icon="plus" color="#fff" />
          </CircleBox>
        )}
        {_renderEmptyCircles}
      </Box>
      {_renderSeasonDetails}
      <Link to={managePath}>
        <StyledAnchor>
          <EditButton>Edit Seasons</EditButton>
        </StyledAnchor>
      </Link>
    </SectionBox>
  );
};

const StyledAnchor = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export default SeasonsSection;
