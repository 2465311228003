import { Box, Button, Loader, SearchInput, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { Table as _Table } from "../../common/TableElements";
import { TabWrapper } from "../common";
import useSort from "../useSort";
import Headers from "./Headers";
import Row from "./Row";

const WARNING = "Are you absolutely certain that you want to proceed with the deletion of this contribution?";

const ContributionsTab = ({ id, title }) => {
  const [loading, setLoading] = useState(false);
  const { contributionsSeason } = useStoreState((state) => state.season);
  const { getContributionsSeason, removeContributionRequest } = useStoreActions((actions) => actions.season);
  const { total } = useStoreState((state) => state.season.pagination.contributions);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { onChange, fetchRecords, ...sortProps } = useSort(getContributionsSeason, "status", "id", id);

  const deleteClick = (e) => {
    const { id: contribution_id } = e.currentTarget.dataset;

    const action = () => removeContributionRequest({ id, contribution_id }).then(fetchRecords);

    showModal({
      modalName: "ConfirmDeletionModal",
      action,
      title: "Delete Contribution?",
      confirmText: "Delete Contribution",
      warning: WARNING,
    });
  };

  const onClick = (e) => showModal({ modalName: "CreateContributionModal", id, callBack: fetchRecords });

  if (loading) {
    return (
      <Box flex justifyContent="center" spacing="my-a">
        <Loader $size="64px" />
      </Box>
    );
  }

  return (
    <TabWrapper>
      <Box flex justifyContent="space-between">
        <Text typography="heading/small" bold>
          There are {total} Contributors in {title}
        </Text>
        <Box flex gap="16px">
          <Button size="sm" borderRadius="40px" rightIcon="plus" typography="other/buttons" onClick={onClick}>
            Create Contribution
          </Button>
          <SearchInput className="search-input" onChange={onChange} />
        </Box>
      </Box>
      <Table width="100%">
        <Headers {...sortProps} />
        <tbody>
          {contributionsSeason.map((item) => (
            <Row key={item.id} {...item} deleteClick={deleteClick} />
          ))}
        </tbody>
      </Table>
    </TabWrapper>
  );
};

const Table = styled(_Table)`
  tbody tr td, thead th {
    padding: 24px;
  }
`;

export default ContributionsTab;
