import toFormData from "@utils/toFormData";
import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("badges");
const communityRequester = new HttpWrapper("communities");

const attributes = () => ({
  badges: [],
  communityBadges: [],
});

const actions = {
  createBadge: thunk((_, { _data, community_id }) => {
    const badge = toFormData("badge", _data.data);
    const request = community_id
      ? communityRequester.post(`${community_id}/create_badge`, badge)
      : requester.post("", badge);
    return request;
  }),
  getBadges: thunk(
    ({ setBadges }, { sort_type = "name", sort_direction = "desc", keyword = "", community_ids, system }) => {
      const params = { sort_type, sort_direction, community_ids, system };
      if (keyword) {
        params.keyword = keyword;
      }
      return requester.get("/list", params).then(({ badges }) => {
        setBadges(badges);
      });
    }
  ),
  getCommunityBadges: thunk(({ setCommunityBadges }, { community_id, keyword = "" }) => {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    return communityRequester.get(`${community_id}/badges`, params).then(({ badges }) => {
      setCommunityBadges(badges);
    });
  }),
  setBadges: action((state, badges) => {
    state.badges = badges;
  }),
  setCommunityBadges: action((state, communityBadges) => {
    state.communityBadges = communityBadges;
  }),
};

const badges = {
  ...attributes(),
  ...actions,
};

export default badges;
