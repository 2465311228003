import DefaultFeatured from "@assets/dashboard/SeasonFeaturedBlockchain.svg";
import { Box, breakpoints, Button, Loader, OptionsPanel, SeasonCardManage, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MainContainer as _MainContainer } from "../common/MainContainer";
import { StatusFilter } from "./ManageCommunityCard/common";

const getGapSize = (number) => {
  const digitCount = Math.abs(number).toString().length;

  if (digitCount === 1) {
    return "16px";
  } else if (digitCount === 2) {
    return "28px";
  } else if (digitCount === 3) {
    return "40px";
  } else if (digitCount === 4) {
    return "47px";
  } else {
    return digitCount + "px";
  }
};

const getMaxDigitObject = (objects, key) => {
  let maxDigitCount = 0;
  let objectWithMaxDigits = null;

  for (const obj of objects) {
    const digitCount = Math.abs(obj[key]).toString().length;

    if (digitCount > maxDigitCount) {
      maxDigitCount = digitCount;
      objectWithMaxDigits = obj;
    }
  }

  return objectWithMaxDigits;
};

const FilterType = [
  { value: "active", label: "active", activeColor: "03Primary500", selected: true },
  { value: "draft", label: "draft", activeColor: "01Secondary500" },
  { value: "completed", label: "completed", activeColor: "03Primary500" },
  { value: "upcoming", label: "upcoming", activeColor: "02Primary200" },
  { value: "open_to_claim", label: "open to claim", activeColor: "03Primary500" },
  { value: "upcoming_raffle", label: "upcoming raffle", activeColor: "03Primary500" },
  { value: "claim_opens_soon", label: "claim opens soon", activeColor: "02Primary200" },
];

const SeasonsTab = () => {
  const { id } = useParams();
  const { filteredSeasons } = useStoreState((state) => state.season);
  const { getFilteredSeasons } = useStoreActions((actions) => actions.season);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(FilterType[0].value);
  const navigate = useNavigate();

  const orderNums = filteredSeasons.length > 0 && getMaxDigitObject(filteredSeasons, "order_number");
  const onEditSeason = (id) => showModal({ modalName: "SeasonEditorModal", seasonID: id, edit: true });

  const doPublish = (event) =>
    showModal({ modalName: "PublishSeasonModal", id: event.target.dataset.id, callBack: fetchData });

  const doEdit = (event) => onEditSeason(event.target.dataset.id);

  const addSeason = () => showModal({ modalName: "SeasonEditorModal" });

  const handleChange = (event) => setStatus(event.target.dataset.value);

  const fetchData = () => getFilteredSeasons({ id, status: status.toLowerCase() }).finally(() => setLoading(false));

  const onExportClick = () => {
    showModal({
      modalName: "ExportDataModal",
      id: id,
    });
  };

  const doAddWays = (event) => {
    const { id } = event.currentTarget.dataset;
    navigate(`/manage/seasons/${id}`);
  };
  const doMoreDetails = (event) => {
    const { id } = event.currentTarget.dataset;

    showModal({
      modalName: "AddSmartContractModal",
      id: id,
      fetchData: fetchData,
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [id, status]);

  return (
    <MainContainer>
      <StatusFilter filters={FilterType} status={status} onStatusClick={handleChange}>
        <Box spacing="ml-a" flex gap="16px" alignItems="center">
          <ExportButton
            onClick={onExportClick}
            rightIcon="export-cloud"
            kind="outline"
            variant="01Primary700"
            size="sm"
            borderRadius="50px"
          >
            Export Data (CSV)
          </ExportButton>
          <Button borderRadius="40px" rightIcon="arrow-up-right" onClick={addSeason} size="sm">
            Add New Season
          </Button>
        </Box>
      </StatusFilter>
      <Box>
        <Header>
          <Box flex gap={getGapSize(orderNums.order_number)}>
            <Text typography="body/small" bold color="01Primary700">
              Status
            </Text>
            <Text typography="body/small" bold color="01Primary700">
              Season
            </Text>
          </Box>
          <Box flex justifyContent="space-between">
            <Text typography="body/small" bold color="01Primary700">
              Type
            </Text>
            <Text typography="body/small" bold color="01Primary700">
              Token
            </Text>
            <Text typography="body/small" bold color="01Primary700">
              Badge
            </Text>
          </Box>
        </Header>

        {loading && (
          <Box width="100%" spacing="m-a p-9+6" flex alignItems="center" justifyContent="center">
            <Loader $size="50px" />
          </Box>
        )}
        {!loading &&
          filteredSeasons.map((item) => {
            return (
              <SeasonBox key={item.id}>
                <SeasonCardManage
                  season_number={item.order_number}
                  {...item}
                  manageSeason
                  doAddWays={doAddWays}
                  onStart={() => onEditSeason(item.slug || item.id, "seasons")}
                  end_date={moment(item.end_date).format("MMM Do, YYYY")}
                  CloseSeasonPanel={CloseSeasonPanel(fetchData)}
                  refresh={fetchData}
                  doPublish={doPublish}
                  doEdit={doEdit}
                  doManageSeason={doAddWays}
                  featured_image_url={item.featured_image_url || DefaultFeatured}
                  doMoreDetails={doMoreDetails}
                  doSmartContract={doMoreDetails}
                />
              </SeasonBox>
            );
          })}
      </Box>
    </MainContainer>
  );
};

const MainContainer = styled(_MainContainer)`
  ${Button} {
    text-wrap: nowrap;
  }
`;

const CloseSeasonPanel =
  (fetchData) =>
  ({ id }) => {
    const { showModal } = useStoreActions((actions) => actions.modals);
    const onClick = () => showModal({ modalName: "CompleteSeasonModal", id, callback: fetchData });

    return (
      <OptionsPanel right="0" hasBackground>
        <Text onClick={onClick} pointer typography="body/medium" color="error2">
          Complete Season
        </Text>
      </OptionsPanel>
    );
  };

const SeasonBox = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const ExportButton = styled(Button)`
  svg {
    width: 22px;
    height: 16px;
  }
`;
const Header = styled(Box)`
  display: grid;
  grid-template-columns: 575px 213px 1fr;
  padding: 12px 24px;
  @media ${breakpoints.belowDesktop} {
    display: none;
  }
`;

export default SeasonsTab;
