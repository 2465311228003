import HeaderElement from "@common/HeaderElement";
import { breakpoints } from "@thrivecoin/ui-components";
import styled from "styled-components";

const Headers = ({ onSort, sortType, sortDirection }) => {
  return (
    <thead>
      <Row>
        <HeaderElement
          text="Contributor"
          name="username"
          onSort={onSort}
          sort={sortType === "username" ? sortDirection : null}
        />

        <HeaderElement
          text="Total Season Earnings"
          name="reward_amount"
          onSort={onSort}
          sort={sortType === "reward_amount" ? sortDirection : null}
        />

        <HeaderElement
          text="Transaction Hash"
          name="tx_id"
          onSort={onSort}
          sort={sortType === "tx_id" ? sortDirection : null}
        />

        <HeaderElement
          text="Status"
          name="status"
          onSort={onSort}
          sort={sortType === "status" ? sortDirection : null}
        />
      </Row>
    </thead>
  );
};

const Row = styled.tr`
  th {
    @media ${breakpoints.belowDesktop} {
      padding-top: 12px;
      padding-bottom: 14.57px;
    }
  }
`;

export default Headers;
