const config = Object.freeze({
  polygon: {
    chainId: "0x89",
    chainName: "Polygon MainNet",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  goerli: {
    chainId: "0x5",
    chainName: "Görli",
    nativeCurrency: {
      name: "Görli Ether",
      symbol: "GOR",
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_GOERLI_RPC_URL],
    blockExplorerUrls: [
      "https://goerli.etherscan.io/",
      "https://blockscout.com/eth/goerli/",
      "https://explorer.goerli.net/",
    ],
  },
  polygonMumbai: {
    chainId: "0x13881",
    chainName: "Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_MUMBAI_RPC_URL],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  optimism: {
    chainId: "0xa",
    chainName: "OP Mainnet",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.optimism.io"],
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
  },
  ethereum: {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://eth.llamarpc.com"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  arbitrum: {
    chainId: "0xa4b1",
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "Etherum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arbitrum.llamarpc.com"],
    blockExplorerUrls: ["https://arbiscan.io"]
  }
});

export default config;
