import { Box, Button, Checkbox, IconSvg, Text, Tooltip } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { CircledIcon, CircleImage } from "../common";

const STATUS_MESSAGE = "Inactive. Click “Add Reward” to turn this contribution active.";

const LIMIT_MESSAGE = "Reward limit reached. To reactivate this contribution, increase the limit via “Edit Rewards”";

export const SeasonListingRow = ({
  name,
  image_url,
  id,
  listing_id,
  reward,
  max_rewards,
  updateReward,
  className,
  selected,
  status,
  OptionsComponent,
  previewListing,
  limit_reached,
  ...props
}) => (
  <tr data-id={id} className={className} data-selected={selected} data-status={status}>
    <td>
      <Checkbox name={`checkbox-${id}`} checked={selected} data-id={id} kind="gray-check" />
    </td>
    <td>
      <Box flex gap="12px">
        <CircleImage src={image_url} />
        <Text typography="body/medium" bold>
          {name}
        </Text>
      </Box>
    </td>
    <td>{reward}</td>
    <td data-limit={limit_reached}>
      <Tooltip
        direction="top"
        $minWidth="300px"
        bgColor="01Primary700"
        $color="01Primary0"
        $show={limit_reached}
        message={LIMIT_MESSAGE}
      >
        <Box flex gap="8px">
          {limit_reached && <IconSvg icon="alert-triangle" color="Error2" />}
          {max_rewards}
        </Box>
      </Tooltip>
    </td>

    <td>
      <Box flex gap="16px" alignItems="center">
        <Tooltip
          direction="top"
          $minWidth="300px"
          bgColor="01Primary700"
          $color="01Primary0"
          message={STATUS_MESSAGE}
          $show={status == "pending"}
        >
          <CircleStatus icon={status == "pending" ? "x" : "check"} />
        </Tooltip>
        <Button
          data-id={id}
          size="sm"
          borderRadius="40px"
          typography="Other/Buttons"
          onClick={updateReward}
          width="146px"
          {...BUTTON_CONFIG[status]}
        >
          {BUTTON_CONFIG[status].text}
        </Button>
        <CircledIcon icon="eye2" width="16px" onClick={previewListing} data-id={id} data-listing-id={listing_id} />
        <OptionsComponent id={id} {...props} updateReward={updateReward} />
      </Box>
    </td>
  </tr>
);

const BUTTON_CONFIG = {
  active: {
    typography: "Other/Buttons",
    kind: "outline",
    borderColor: "01Secondary500",
    text: "Edit Reward",
  },
  pending: {
    text: "Add Reward",
    rightIcon: "plus",
  },
};

const CircleStatus = styled(IconSvg)`
  border-radius: 50%;
  background: red;
  padding: 8px;
  color: white;
  background: ${({ theme, icon }) => (icon == "check" ? theme.colors["03Primary500"] : theme.colors["02Secondary500"])};
`;

SeasonListingRow.defaultProps = {
  description: "Vote on all 4 of the Questbook Domain Allocators polls on Snapshot [Raffle] -- Sep. 6 to Sep. 13",
  image: "https://thrivecoin-assets.s3.amazonaws.com/images/apps/discord.svg",
  reward: "-",
  maxReward: "-",
  selectedIDs: [],
};

export default SeasonListingRow;
