import { Box, Button, getTypography, SearchInput, Text } from "@thrivecoin/ui-components";
import debounce from "@utils/debounce";
import { useStoreActions } from "easy-peasy";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { StatusFilter } from "../../ManageCommunities/ManageCommunityCard/common";
import { CommunityListingOptions, IconContextMenu } from "../common";
import ListingsTable from "./ListingsTable";
import useDragAndDrop from "./useDragAndDrop";
import useSelectionColumn from "./useSelectionColumn";

const DEBOUNCE_TIME = 700;

const PERSISTENT_OPTIONS = [
  { value: "active", label: "Active" },
  { value: "draft", label: "Draft" },
];

const filterListings = (listings, status) => {
  if (status) {
    return listings.filter((listing) => listing.status == status);
  }

  return listings;
};

const ListingsList = ({
  title,
  community_name,
  listings,
  addListingsToSeason,
  searchListings,
  previewListing,
  seasonID,
  statusCounts,
  community_id,
}) => {
  const [status, setStatus] = useState();
  const [optionsWithCounts, setOptions] = useState([...PERSISTENT_OPTIONS]);
  const filteredListings = filterListings(listings, status);
  const { archiveRequest } = useStoreActions((actions) => actions.listings);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const navigate = useNavigate();

  const { selectedIDs, onSelectChange, selectAll, clearSelection, selectByStatus } =
    useSelectionColumn(".community-listings");
  const selectedCount = selectedIDs.length;
  const text =
    selectedCount > 0
      ? `${selectedCount} Selected Ways to Contribute`
      : `There are ${listings.length} Created Ways to Contribute in ${community_name} Community`;

  const onChange = useMemo(() => {
    return debounce(searchListings, DEBOUNCE_TIME);
  }, []);

  const onClear = () => searchListings("");

  //  Adding listings
  const addSelectedToSeason = (e) => addListingsToSeason(selectedIDs).then(clearSelection);
  const addSeason = (event) => {
    const { id } = event.target.dataset;
    addListingsToSeason(id);
  };

  const editListing = (event) => {
    const { id } = event.currentTarget.dataset;
    showModal({ modalName: "ListingEditorModal", listingID: id });
  };

  const archiveSelected = (e) => {
    const promises = selectedIDs.map((id) => archiveRequest(id));
    return Promise.all(promises);
  };

  const draftSelected = (e) => {
    debugger;
  };

  //Might not need all this since  ondrop is on the other list and the other list is also rearrangeable
  const { bindItems, removeItems } = useDragAndDrop({ itemSelector: ".dragable", onChange });

  const goToWaysClick = (event) => {
    const url = `/manage/communities/${seasonID}?tabIndex=1`;
    navigate(url);
  };

  const AddNewWayClick = (event) => {
    const url = `/manage/communities/${community_id}?tabIndex=1&createListing=true`;
    navigate(url);
  };

  const onTagClick = (event) => setStatus(event.target.dataset.value);

  const listingProps = {
    previewListing,
    addSeason,
    editListing,
    OptionsComponent: CommunityListingOptions,
  };

  useEffect(() => {
    bindItems();
  }, [listings]);

  useEffect(() => {
    const keys = Object.keys(statusCounts);

    if (keys.length) {
      const updatedOptions = [...PERSISTENT_OPTIONS];
      keys.forEach((key) => {
        let item = updatedOptions.find((x) => x.value == key);
        item.label = `${capitalize(key)} (${statusCounts[key]})`;
      });
      setOptions(updatedOptions);
    }
  }, [statusCounts]);

  return (
    <Container onChange={onSelectChange} flex direction="column" gap="32px">
      <Box flex gap="16px" alignItems="center" minHeight="35px">
        <Text bold typography="Heading/Small">
          {text}
        </Text>
        {selectedIDs.length > 0 && (
          <IconContextMenu>
            <StiledList>
              <li onClick={addSelectedToSeason}>Add to {title}</li>
              <li onClick={clearSelection}>Clear Selection</li>
              {/* <li onClick={draftSelected}>Move to Draft</li> */}
              {/* <li onClick={archiveSelected}>Move to Archive</li> */}
            </StiledList>
          </IconContextMenu>
        )}
      </Box>

      <StatusFilter filters={optionsWithCounts} status={status} onStatusClick={onTagClick} spacing="py-5 px-4+1">
        <Box flex gap="16px" grow="2" spacing="ml-a">
          <Button borderRadius="40px" size="sm" kind="outline" borderColor="01Primary700" onClick={goToWaysClick}>
            Go to Manage Ways to Contribute Page
          </Button>
          <Button borderRadius="40px" size="sm" rightIcon="plus" onClick={AddNewWayClick}>
            Add New Way to Contribute
          </Button>
          <SearchInput className="search-input" onChange={onChange} />
        </Box>
      </StatusFilter>

      <ListingsTable
        selectAll={selectAll}
        listings={filteredListings}
        listingProps={listingProps}
        selectedIDs={selectedIDs}
        rowClass="dragable"
        className="community-listings"
      >
        <ul>
          <li onClick={selectAll}>All</li>
          <li onClick={clearSelection}>None</li>
          <li onClick={selectByStatus} data-status="pending">
            Pending
          </li>
          <li onClick={selectByStatus} data-status="active">
            Active
          </li>
        </ul>
      </ListingsTable>
    </Container>
  );
};

const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

const Container = styled(Box)`
  ${ListingsTable} {
    th {
      padding-top: 0px;
    }
  }
`;

const StiledList = styled.ul`
  && {
    li {
      font-weight: normal;
      ${getTypography("body/medium")}

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
      }

      &:last-child {
        color: ${({ theme }) => theme.colors["error"]};
      }

      &:first-child {
        font-weight: 700;
      }
    }
  }
`;

export default ListingsList;
