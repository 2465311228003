import CardHand from "@assets/profile/card-hand.svg";
import { Box, breakpoints, Button, IconSvg, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CheckBox from "../common/CheckBox";
import useWeb3 from "../hooks/useWeb3";

const EligibleContent = () => (
  <>
    <Text textAlign="center">
      <b>Hurray!</b> You are eligible to participate in Thank ARB Milestone 1 and complete contribution listings for
      tARB, our platform credit and reputation token. Your wallet has been confirmed to hold ARB as of an allowlist
      snapshot taken on November 1, 2023 as adjusted by TrustaLabs for sybil detection. Each Thank ARB milestone may use
      a different allowlist of eligible wallets.
    </Text>
    <Text textAlign="center" spacing="my-5">
      Please note that some, but not all, of tARB earned may qualify you to earn ARB. However, in order to qualify for
      claiming ARB rewards you must verify your email address on ThriveCoin.
    </Text>
    <Text textAlign="center">
      Also, make sure to follow us on X at{" "}
      <a href="https://twitter.com/thankarbitrum" target="_blank" rel="noreferrer">
        @ThankArbitrum
      </a>
      !{" "}
    </Text>
  </>
);

const IneligibleContent = ({ checked, toggleCheckbox }) => (
  <>
    <Text textAlign="center">
      Unfortunately, you are not eligible to participate in Thank ARB Milestone 1 because your wallet was not found on
      an allowlist snapshot of ARB holders taken on November 1, 2023 as adjusted by TrustaLabs for sybil detection.{" "}
    </Text>
    <Text spacing="mt-5" textAlign="center">
      If you think this was an error, please “Sign out” from your current wallet in the top right hand corner and
      connect a different wallet. To participate in future seasons, please make sure your wallet holds at least one ARB.
    </Text>
    <Text spacing="mb-5" textAlign="center">
      Also, make sure to follow us on X at{" "}
      <a href="https://twitter.com/thankarbitrum" target="_blank" rel="noreferrer">
        @ThankArbitrum
      </a>!
    </Text>
    <NarrowBox flex gap="12px" alignItems="flex-start">
      <CheckBox checked={checked} onChange={toggleCheckbox} kind="gray-check"/>
      <Text> I acknowledge that I will not receive any rewards for participating in this season of Thank ARB.</Text>
    </NarrowBox>
  </>
);

const UnauthenticatedContent = () => (
  <NarrowBox>
    <Text textAlign="center">
      To be able to continue and contribute to this season you will need to connect your Wallet first.
    </Text>
    <Text textAlign="center" spacing="mt-5">
      By connecting your wallet you’re agreeing to the ThriveCoin{" "}
      <Link to="/terms_services" target="_blank" rel="noopener noreferrer">
        <b>Terms of Service</b>
      </Link>
      .
    </Text>
  </NarrowBox>
);

const CONFIGS = {
  eligible: {
    title: "Eligible for Rewards",
    content: EligibleContent,
    buttonLabel: "Agree & Close",
    paddingBody: "40px 55px 64px",
    mobilePadding: "32px 24px",
    topIcon: "check-circle-filled",
    iconColor: "03Primary500",
  },
  ineligible: {
    title: "Ineligible for Rewards",
    content: IneligibleContent,
    buttonLabel: "Agree & Close",
    paddingBody: "40px 55px 64px",
    mobilePadding: "32px 24px",
    topIcon: "remove-danger",
    iconColor: "#E06363",
  },
  unauthenticated: {
    title: "Connect Wallet",
    content: UnauthenticatedContent,
    buttonLabel: "Connect Wallet",
    leftIcon: "wallet-filled",
    paddingBody: "64px 55px 0",
    mobilePadding: "32px 24px 0",
    showBottomImage: true,
  },
};

const SeasonEligibilityModal = ({ hideModal, type, seasonId }) => {
  const config = CONFIGS[type] || CONFIGS.ineligible;
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);
  const { isMobile } = useStoreState((state) => state.media);
  const { loginFlow, installMetamask, hasWeb3Provider } = useWeb3();
  const { user } = useStoreState((state) => state.user);
  const referral_code = sessionStorage.getItem("referral-code");
  const buttonDisabled = type === "ineligible" && !agreed;

  const Kontent = config.content;

  const goToDashboard = () => {
    hideModal();
    navigate("/");
  };
  const onClick = () => {
    if (type === "unauthenticated") {
      hideModal();
      hasWeb3Provider ? loginFlow(referral_code) : installMetamask();
    } else {
      const agreed = `agreed_${user?.id}_${seasonId}`;
      localStorage.setItem(agreed, true);
      hideModal();
    }
  };
  const toggleCheckbox = (e) => {
    const { checked } = e.target;
    setAgreed(checked);
  };

  const footerContent = (
    <>
      <NarrowBox spacing="mt-6">
        <ButtonsWrapper direction={isMobile && "column"} flex>
          <Button
            kind="outline"
            variant="02Primary500"
            color="02Primary500"
            borderRadius="50px"
            grow={1}
            onClick={goToDashboard}
          >
            Go to Dashboard
          </Button>
          <StyledButton
            disabled={buttonDisabled}
            color="02Primary0"
            borderRadius="50px"
            grow={1}
            leftIcon={config.leftIcon}
            onClick={onClick}
          >
            {config.buttonLabel}
          </StyledButton>
        </ButtonsWrapper>
      </NarrowBox>

      {config?.showBottomImage && <CardHandImg src={CardHand} />}
    </>
  );

  return (
    <Modal open maxWidth="764px" onClose={hideModal} footerContent={footerContent}>
      <Box flex direction="column" color="02Primary500">
        {config?.topIcon && <StyledIcon icon={config.topIcon} color={config.iconColor} width="40px" />}
        <Text typography="heading/large" spacing="mb-4" textAlign="center">
          {config.title}
        </Text>
        <Kontent checked={agreed} toggleCheckbox={toggleCheckbox} />
      </Box>
    </Modal>
  );
};

const StyledIcon = styled(IconSvg)`
  margin: 0 auto 24px;
`;

const NarrowBox = styled(Box)`
  margin: 0 65px;

  @media ${breakpoints.mobile} {
    margin: 0;
  }
`;

const ButtonsWrapper = styled(Box)`
  gap: 32px;

  @media ${breakpoints.mobile}, ${breakpoints.miniTablet} {
    gap: 24px;
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  &:disabled {
    background: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    color: ${({ theme }) => theme.colors["02Primary0"]};
  }
`;

const CardHandImg = styled.img`
  margin: 32px auto 0;
  max-width: 390px;
`;

export default SeasonEligibilityModal;
