import { Box, IconSvg, Text, TokenReward } from "@thrivecoin/ui-components";
import { CircleImage } from "../common";

const Row = ({ image_url, username, reward_amount, reward_token, tx_id, status }) => {
  return (
    <tr>
      <td>
        <Box flex gap="12px">
          <CircleImage $size="44px" src={image_url} />
          <Text typography="body/medium" bold>
            {username}
          </Text>
        </Box>
      </td>
      <td>
        <TokenReward amount={reward_amount} token={reward_token} color="01Primary700" hideText />
      </td>
      <td>{tx_id}</td>
      <td>
        <Status status={status} />
      </td>
    </tr>
  );
};

const Status = ({ status }) => (status == "claimed" ? <ClaimedStatus /> : <PendingStatus />);

const StatusBox = ({ color, icon, text }) => (
  <Box flex gap="6px" alignItems="center" color={color}>
    <IconSvg icon={icon} />
    <Text typography="Body/Medium" bold>
      {text}
    </Text>
  </Box>
);

const ClaimedStatus = () => <StatusBox icon="check-circle-filled" text="Claimed" color="03Primary500" />;
const PendingStatus = () => <StatusBox icon="clock-filled" text="Pending" color="01Secondary500" />;

export default Row;
