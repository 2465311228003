import { Box, Loader } from "@thrivecoin/ui-components";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import Separator from "../../common/Separator";
import { TabWrapper } from "../common";
import ListingDropBox from "./ListingDropBox";
import ListingsList from "./ListingsList";
import { ManageSeasonProvider, SeasonWaysToContributeContext } from "./SeasonWaysToContributeContext";

const AddListings = () => {
  const { id } = useParams();
  return (
    <ManageSeasonProvider id={id}>
      <_AddListings />
    </ManageSeasonProvider>
  );
};

const _AddListings = () => {
  const {
    season,
    communityListings,
    seasonListings,
    seasonListingsTotal,
    addListingsToSeason,
    dropAddListingToSeason,
    searchCommunitySeasons,
    searchListingSeasons,
    previewListing,
    updateReward,
    statusCounts,
    loading,
  } = useContext(SeasonWaysToContributeContext);
  const { title, community_name, community_id } = season;

  if (loading) {
    return (
      <Box flex justifyContent="center" spacing="my-a">
        <Loader $size="64px" />
      </Box>
    );
  }

  return (
    <TabWrapper flex direction="column" gap="32px">
      <ListingDropBox
        title={title}
        listings={seasonListings}
        onDrop={dropAddListingToSeason}
        searchListings={searchListingSeasons}
        previewListing={previewListing}
        seasonListingsTotal={seasonListingsTotal}
        updateReward={updateReward}
      />
      <Separator />
      <ListingsList
        statusCounts={statusCounts}
        title={title}
        community_name={community_name}
        community_id={community_id}
        listings={communityListings}
        seasonID={season.id}
        addListingsToSeason={addListingsToSeason}
        searchListings={searchCommunitySeasons}
        previewListing={previewListing}
      />
    </TabWrapper>
  );
};

export default AddListings;
