import { Button, getTypography } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: left;

  thead th {
    ${getTypography("body/small")}
  }

  tbody {
    td[data-error="true"] {
      color: ${({ theme }) => theme.colors["error2"]};
    }

    tr[data-selected="true"] {
      background: ${({ theme }) => theme.colors["manageCard3"]};
    }

    tr {
      background: ${({ theme }) => theme.colors["01Primary0"]};
    }

    tr:first-child {
      td:first-child {
        border-top-left-radius: 8px;
      }

      td:last-child {
        border-top-right-radius: 8px;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    tr:not(:last-child) td {
      border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
    }
  }

  td,
  th {
    padding: 16px;
  }

  th {
    white-space: nowrap;
  }

  ${Button} {
    white-space: nowrap;
  }
`;
